const TestimonyData = [
  {
    comment:
      "Wouessi provided us with highly skilled developers who integrated smoothly with our team. Their talent outsourcing service is reliable and efficient. ",
    name: "Melissa M.",
    role: "Level Up",
    location: "(New York, USA)",
  },
  {
    comment:
      "Wouessi has a highly professional, reliable, responsive, and versatile team that offers a wide range of services, including design and translation, with the same level professionalism. I Highly recommend their services. ",
    name: "Maxine P.",
    role: "Aldelia",
    location: "(London, UK)",
  },
  {
    comment:
      "Thanks to Wouessi’s AI and blockchain consulting, we’ve been able to innovate and stay ahead of the competition. Their insights and solutions are truly cutting-edge.",
    name: "Yannick L.",
    role: "Kasi Insight",
    location: "(Toronto, Canada)",
  },
  {
    comment:
      "Thanks to Wouessi Inc., our digital transformation is complete. Their expertise in app development, cybersecurity, and digital marketing has significantly boosted our business efficiency and online presence.",
    name: "Guy K.",
    role: "ATR Cyber",
    location: "(Los Angeles, USA)",
  },
  {
    comment:
      "Wouessi helped us redefine our brand identity. Their design team is incredibly talented, and the new brand look has resonated well with our audience.",
    name: "Caroline A.",
    role: "Rondol",
    location: "(Paris, France)",
  },
  {
    comment:
      "Wouessi Digital transformed our company’s brand identity with their creative website design. Their expertise has significantly increased our traffic and engagement. The team at Wouessi Inc. delivered outstanding results on our latest project. Highly recommended.",
    name: "Naser V.",
    role: "HCCM",
    location: "(San Francisco, USA)",
  }
];

export default TestimonyData;
