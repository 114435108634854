import ExpressJS from "../assets/SVG/TechLogo/ExpressJS.svg";
import ReactJS from "../assets/SVG/TechLogo/ReactJS.svg";
import Vue from "../assets/SVG/TechLogo/Vue.svg";
import Angular from "../assets/SVG/TechLogo/Angular.svg";
import Node from "../assets/SVG/TechLogo/Node.svg";
import Mongo from "../assets/SVG/TechLogo/Mongo.svg";
import PHP from "../assets/SVG/TechLogo/PHP.svg";
import Python from "../assets/SVG/TechLogo/Python.svg";
import Ruby from "../assets/SVG/TechLogo/Ruby.svg";
import Java from "../assets/SVG/TechLogo/Java.svg";
import Laravel from "../assets/SVG/TechLogo/Laravel.svg";
import Django from "../assets/SVG/TechLogo/Django.svg";
import RubyOnRails from "../assets/SVG/TechLogo/RubyOnRails.svg";
import Redis from "../assets/SVG/TechLogo/Redis.svg";
import AWS from "../assets/SVG/TechLogo/AWS.svg";
import Firebase from "../assets/SVG/TechLogo/Firebase.svg";
import Apache from "../assets/SVG/TechLogo/Apache.svg";
import MySQL from "../assets/SVG/TechLogo/MySQL.svg";
import DotNet from "../assets/SVG/TechLogo/DotNet.svg";
import Jenkins from "../assets/SVG/TechLogo/Jenkins.svg";
import Lambda from "../assets/SVG/TechLogo/Lambda.svg";
import GoogleCloud from "../assets/SVG/TechLogo/GoogleCloud.svg";
import Azure from "../assets/SVG/TechLogo/Azure.svg";
import Kubemetes from "../assets/SVG/TechLogo/Kubemetes.svg";
import NextJS from "../assets/SVG/TechLogo/NextJS.svg";

const TeckLogos = [
    ExpressJS,
    ReactJS,
    Vue,
    Angular,
    Node,
    Mongo,
    PHP,
    Python,
    Ruby,
    Java,
    Laravel,
    Django,
    RubyOnRails,
    Redis,
    AWS,
    Firebase,
    Apache,
    MySQL,
    DotNet,
    Jenkins,
    Lambda,
    GoogleCloud,
    Azure,
    Kubemetes,
    NextJS,
  ];

  export default TeckLogos;